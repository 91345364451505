import Domain from "../domain";
export default {
  methods: {
    getMainCategories: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/category/details?status=1",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getXLDownlord: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/category/markup/download",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getSubCategoryById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/category/details?category="+ id +"&status=1",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getCategoryassignsByID: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/category/assign/"+id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
