<template>
  <div class="merchprdlist">
    <div class="panel-body row"  v-if="!kycVerify">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body" style="color: red; font-size: large; text-align: center; font-weight: 800;">
            Your details are being verified by our Team. Once verified you will be able to add products. 
          </div>
        </div>
      </div>
    </div>
    <div class="panel-body row"  v-if="!isDeliveryApprove">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body" style="color: red; font-size: large; text-align: center; font-weight: 800;">
            Your Own Delivery Account details are being verified by our Team. Once verified you will be able to add products. 
          </div>
        </div>
      </div>
    </div>
    <div v-if="kycVerify && isDeliveryApprove">
    <div class="panel-body row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
            <input
              type="text"
              style="font-size: 16px;"
              v-model="searchKey"
              class="form-control"
              id="input-store"
              placeholder="Search Product"
              value
              name="voucher"
            />
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-left: 0px;">
            <button
              type="button"
              class="btn merchprof-sbtn"
              style="min-height: 30px;"
              @click="searchProduct()"
              :disabled="isDisabled"
            >Search</button>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="padding-right: 0px;">
        <div class="row" style="margin-left: 0px;margin-right: 0px;">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            v-if="userRole === 1 || userRole === 4"
            style="text-align: right;"
          >
            <button
              type="button"
              class="btn merchprof-sbtn"
              style="margin-right: 15px;"
              @click="handleCreate"
            >Add New</button>
            <button
              type="button"
              class="btn merchprof-sbtn"
              style="margin-right: 15px;"
              @click="handleCreateBulk()"
            >Add New Bulk</button>
            <button
              type="button"
              class="btn merchprof-sbtn"
              @click="handleUpdateStockBulkSup()"
              v-if="(userRole === 1 || userRole === 4) && isVisibleAllEdit === false"
            >Update Stock Bulk</button>

            <button
              type="button"
              class="btn merchprof-sbtn"
              @click="handleUpdateFreeshippingBulk()"
              v-if="(userRole === 1 || userRole === 4) && isVisibleAllEdit === true"
            >Update Free shipping Bulk</button>

          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            v-if="userRole === 2"
            style="text-align: right;"
          >
            <button
              type="button"
              class="btn merchprof-sbtn"
              @click="handleUpdateStockBulk()"
            >Update Stock Bulk</button>
          </div>
          <button
            type="button"
            class="btn merchprof-sbtn"
            style="margin-left: 15px;margin-right: 2px;"
            @click="helpProponover()"
            data-toggle="tooltip" 
            data-placement="top" 
            title="How you can add your products to Cochchi.lk"
          ><i class="fa fa-question" aria-hidden="true"></i></button>
          <div id="helpDiv">
            <div style="margin: 0px; padding: 0px; width: 500px;">
              <div>
                <div style="background:#005baa; text-align: center;">
                  <h4 style="padding: 0; margin: 0; line-height: 40px; color:white;">Here's how you can add your products to <a href="https://cochchi.lk/" style="color: white;" target="_blank">Cochchi.lk</a></h4>
                </div>
                <div style="padding: 10px; text-align: justify; color: black;">
                  <p>Here's how you can add your products to <a href="https://cochchi.lk/" style="color: blue;" target="_blank">Cochchi.lk</a></p>
                  <p>Do you wish to upload your products in Bulk? Then click on the 'Add New Bulk' button and download the Instructions on the page.</p>
                  <p>If you wish to add products one by one, follow the steps below:</p>
                  <p><b>Step 1:</b> If you want to upload images, Visit the Gallery section and upload your product images there. Proceed to Step 2, if you wish to copy paste a URL.</p>
                  <p><b>Step 2:</b> Click on Add New button and select the store from top to which you wish to add the products.</p>
                  <p><b>Step 3:</b> Fill in the Mandatory details and don't forget to select the delivery partner.</p>
                  <p><b>Step 4:</b> Click Submit when you are done and the product will be added to your store.</p>
                </div>
                <!-- <span style="font-size: smaller; color: black;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, sed facere repudiandae molestiae animi odit quia similique neque ipsam esse recusandae culpa, dolore exercitationem eligendi magnam ad molestias velit! Libero?</span><br/>
                <span style="font-weight: 800; color: blue;">Rs.</span> -->
              </div>
            </div>
            <!-- <button style="margin-left: 15px;margin-right: 2px;" class="btn merchprof-sbtn" @click="helpProponover();" type="button" slot="reference"><i class="fa fa-question" aria-hidden="true"></i></button> -->
          </div>

        </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
            style="padding-bottom: 15px"
        >
              <div class="row" style="margin-left: 0px; margin-right: 0px;">
            <div
              class="col-lg-3 col-md-3 col-sm-3 col-xs-3"
            ><h4>category</h4></div>
            <div
              class="col-lg-7 col-md-7 col-sm-7 col-xs-7"
            >
              <select v-model="searchMainCategory" class="form-control select-arrow">
                <option
                  value="0"
                >All</option>
                <option
                  v-for="(option, index) in mainCategory"
                  :key="index"
                  :value="option._id"
                >{{ option.category_name }}</option>
              </select>
            </div>
            <div
              class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
              style="padding-right: 0px; padding-left: 0px;text-align: right;"
            >
              <button
                @click="filterMainCategory()"
                class="btn merchprof-sbtn"
                style="min-height: 35px;"
              >Filter</button>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
            style="padding-bottom: 15px"
        >
              <div class="row" style="margin-left: 0px; margin-right: 0px;">
            <div
              class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
            ><h4>Status</h4></div>
            <div
              class="col-lg-7 col-md-7 col-sm-7 col-xs-7"
            >
              <select v-model="searchProductStatus" class="form-control select-arrow">
                <option
                  value="0"
                >All</option>
                <option
                  v-for="(option, index) in ProductStatusList"
                  :key="index"
                  :value="option._id"
                >{{ option.status }}</option>
              </select>
            </div>
            <div
              class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
              style="padding-right: 0px; padding-left: 0px;text-align: right;"
            >
              <button
                @click="filterProductStatus()"
                class="btn merchprof-sbtn"
                style="min-height: 35px;"
              >Filter</button>
            </div>
          </div>
        </div> -->
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 class="title">Products</h2>
      </div>
    </div>
    <div style="overflow-x: scroll;">
      <table id="example" class="table table-striped table-border-out">
        <thead>
          <tr>
            <th scope="col" class="head-table"></th>
            <th scope="col" class="head-table">Product Name</th>
            <th scope="col" class="head-table">category</th>
            <th scope="col" class="head-table">Qty</th>
            <th scope="col" class="head-table">Listing Price (RS)</th>
            <th scope="col" class="head-table">Selling price (RS)</th>
            <!-- <th scope="col" class="head-table">Short Description</th> -->
            <th scope="col" class="head-table" v-if="userRole === 1 || userRole === 2 || userRole === 4">Action</th>
          </tr>
        </thead>
        <tbody>
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>
          <tr v-for="(column, index) in tableItems" :key="index" :style="[column.admin_status === 2 ? {'background-color': '#ADD8E6', 'color': 'white'} : {'background-color': 'white'}]" >
            <td class="text-left col-md-1" style="vertical-align: middle;">
              
              <img
                v-if="column.simple_products[0].images[0] && column.simple_products[0].images[0].image !== 'undefined'"
                class="img-thumbnail"
                width="70px"
                style="height: 70px;"
                :src="column.simple_products[0].images[0].image"
              />

            </td>
            <td style="padding-top: 26px;font-size:14px;font-weight: 500;">
              <span v-if="column.admin_status === 2 && column.active_status === 1" style="color: red">Pending Approval</span>
              <span  style="color: red" v-if="(column.active_status === 2 && column.admin_status === 1) || (column.active_status === 2 && userRole === 2 && column.admin_status === 1)">Deactivated</span>
              <span v-if="column.admin_status === 2 && column.active_status === 2" style="color: red">Rejected</span> <br/>{{ column.product_name }}</td>
            <td
              style="padding-top: 26px;font-size:14px;font-weight: 500;"
            >{{ column.category_name }}</td>
            <td
              style="padding-top: 26px;font-size:14px;font-weight: 500;"
            >{{ column.simple_products[0].quantity }}</td>
            <td
              style="padding-top: 26px;font-size:14px;font-weight: 500;"
            >{{ column.simple_products[0].price.toFixed(2) }}</td>
            <td
              style="padding-top: 26px;font-size:14px;font-weight: 500;"
            >{{ column.simple_products[0].special_price.toFixed(2) }}</td>
            <!-- <td style="padding-top: 26px;" >{{ column.highlight }}</td> -->
            <td style="padding-top: 18px;" class="product-action-style" v-if="userRole === 1 || userRole === 2 || userRole === 4">
              <button
                v-if="(userRole === 1 || userRole === 4) && !isVisibleAllEdit"
                type="button"
                @click="pushEdit(column._id)"
                class="btn btn-sm btn-primary"
                style="background-color: #2ba12f"
              >Edit</button>&nbsp;
              <!-- <button
                v-if="(userRole === 1 || userRole === 4) && isVisibleAllEdit"
                type="button"
                @click="haddleEditAll(column._id)"
                class="btn btn-sm btn-primary"
                style="background-color: #2ba12f"
              >Edit</button>&nbsp; -->
              <!-- <button
                v-if="(userRole === 1 || userRole === 4) && !isVisibleAllEdit && column.admin_status === 1"
                type="button"
                class="btn btn-sm btn-danger"
                style="background-color: #e15437"
              >Delete</button> -->
              &nbsp;
              <button
                @click="haddleAD(column._id, 1)"
                v-if="
                  (column.active_status === 1 && column.admin_status === 1) ||
                    (column.active_status === 1 && userRole === 2 && column.admin_status === 1)
                "
                type="button"
                class="btn btn-sm"
                style="margin: 0px 5px 0px 1px;background-color: #ffd338; color: white"
              >Deactivate</button>&nbsp;
              <button
                @click="haddleAD(column._id, 2)"
                v-if="
                  (column.active_status === 2 && column.admin_status === 1) ||
                    (column.active_status === 2 && userRole === 2 && column.admin_status === 1)
                "
                type="button"
                class="btn btn-sm btn-primary"
              >Activate</button>
              &nbsp;

              <!-- active / deactive admin user -->
              <!-- <button
                @click="haddleADAdmin(column._id, 1, column.sku)"
                v-if="
                  (column.active_status === 1 && isVisibleAllEdit && column.admin_status === 1) &&
                    (isVisibleAllEdit && column.active_status === 1 && userRole === 1 && column.admin_status === 1) || (isVisibleAllEdit && column.active_status === 1 && userRole === 4 && column.admin_status === 1)
                "
                type="button"
                class="btn btn-sm"
                style="margin: 0px 5px 0px 1px;background-color: #ffd338; color: white"
              >Deactive</button>
              <button
                @click="haddleADAdmin(column._id, 2, column.sku)"
                v-if="
                  (column.active_status === 2 && isVisibleAllEdit) &&
                    (isVisibleAllEdit && column.active_status === 2 && userRole === 1) || (isVisibleAllEdit && column.active_status === 2 && userRole === 4)
                "
                type="button"
                class="btn btn-sm btn-primary"
              >Active</button> -->
              <!-- active / deactive admin user end-->
              <button
                v-if="userRole === 2"
                @click="haddleEditOnlyQty(column._id)"
                type="button"
                class="btn btn-sm btn-primary"
                style="background-color: #17a2b8"
              >Edit</button>&nbsp;
              <button
                v-if="
                  (column.active_status === 1 && column.admin_status === 1) ||
                    (column.active_status === 1 && userRole === 2 && column.admin_status === 1)
                "
                @click="copyUrl(column._id)"
                type="button"
                class="btn btn-sm btn-secondary"
                style="color: black;"
              >Copy URL</button>&nbsp;
              <button
                  v-if="
                    (column.active_status === 1 && column.admin_status === 1) ||
                      (column.active_status === 1 && userRole === 2 && column.admin_status === 1)
                  "
                  @click="openProductView(column._id)"
                  type="button"
                  class="btn btn-sm btn-secondary"
                  style="color: black;"
                >Open Product View</button>&nbsp;
              <div>
                <b-dropdown
                  v-if="
                    (column.active_status === 1 && column.admin_status === 1) ||
                      (column.active_status === 1 && userRole === 2 && column.admin_status === 1)
                  "
                  style="color: black; margin-top: 10px;" 
                  :id="column._id" 
                  text="share" 
                  class="m-md-2"
                >
                  <b-dropdown-item>
                    <div class="shareDiv">
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          flex-wrap: nowrap;
                          justify-content: space-between;
                          align-items: center;
                          align-content: center;
                          padding: 12px 20px;
                          min-width: 255px;
                          background: white;
                        "
                      >
                        <!-- <div class="arrow"></div> -->
                        <div>
                          <span style="color: black;" class="pull-right"
                            >Share via:&nbsp;&nbsp;&nbsp;</span
                          >
                        </div>
                        <social-sharing
                          :url="column.simple_products[0].images[0].image"
                          :title="column.product_name"
                          :media="column.simple_products[0].images[0].image"
                          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                          :quote="column.product_name"
                          twitter-user
                          inline-template
                        >
                          <network network="facebook">
                            <i
                              class="fa fa-facebook-official ml-1"
                              style="font-size: 30px; color: #3b5998 !important"
                            ></i
                            >&nbsp;&nbsp;&nbsp;
                          </network>
                        </social-sharing>
                        <social-sharing
                          :url="column.simple_products[0].images[0].image"
                          :title="column.product_name"
                          :media="column.simple_products[0].images[0].image"
                          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                          :quote="column.product_name"
                          twitter-user
                          inline-template
                        >
                          <network network="twitter">
                            <i
                              class="fa fa-twitter-square ml-1"
                              style="font-size: 30px; color: #5eaade !important"
                            ></i
                            >&nbsp;&nbsp;&nbsp;
                          </network>
                        </social-sharing>
                        <social-sharing
                          :url="column.simple_products[0].images[0].image"
                          :title="column.product_name"
                          :media="column.simple_products[0].images[0].image"
                          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                          :quote="column.product_name"
                          twitter-user
                          inline-template
                        >
                          <network network="pinterest">
                            <i
                              class="fa fa-pinterest-square ml-1"
                              style="font-size: 30px; color: #ca242d !important"
                            ></i
                            >&nbsp;&nbsp;&nbsp;
                          </network>
                        </social-sharing>
                        <social-sharing
                          :url="column.simple_products[0].images[0].image"
                          :title="column.product_name"
                          :media="column.simple_products[0].images[0].image"
                          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                          :quote="column.product_name"
                          twitter-user
                          inline-template
                        >
                          <network network="googleplus">
                            <i
                              class="fa fa-google-plus-square ml-1"
                              style="font-size: 30px; color: #d34836 !important"
                            ></i
                            >&nbsp;&nbsp;&nbsp;
                          </network>
                        </social-sharing>
                        <!-- <social-sharing
                          :url="column.simple_products[0].images[0].image"
                          :title="column.product_name"
                          :media="column.simple_products[0].images[0].image"
                          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                          :quote="column.product_name"
                          twitter-user
                          inline-template
                        >
                          <network network="Tumblr">
                            <i
                              class="fa fa-tumblr-square ml-1"
                              style="font-size: 30px; color: black !important"
                            ></i>&nbsp;&nbsp;&nbsp;
                          </network>
                        </social-sharing> -->
                        <!-- <div>
                          <a>
                            <i
                              class="fa fa-tumblr-square ml-1"
                              style="font-size: 30px; color: black !important"
                            ></i
                            >&nbsp;&nbsp;&nbsp;
                          </a>
                        </div> -->
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <!-- <button
                @click="shareDiwShow()"
                type="button"
                class="btn merchprof-sbtn"
              >share</button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <!-- Modal main -->
    <div
      class="modal fade"
      id="addressModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addressModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg"
        role="document"
        style="padding-top:10%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="float: right;"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="row align-contents">
              <h2 class="modal-title" style="margin-left: 20px;">Edit Product Details</h2>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <form action="#" method="post" enctype="multipart/form-data">
                <div class="col-sm-12">
                  <div class="form-group required">
                    <label class="control-label">Product Name</label>
                    <input
                      type="text"
                      placeholder="Product Name"
                      class="form-control"
                      v-model="allStorePObj.product_name"
                    />
                  </div>

                  <div class="form-group required">
                    <label class="control-label">Description</label>
                    <textarea
                      rows="10"
                      type="text"
                      placeholder="Description"
                      class="form-control"
                      style="min-height: 80px;"
                      v-model="allStorePObj.description"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group required">
                    <label class="control-label">Package Weight - kg</label>
                    <input
                      type="text"
                      placeholder="Package Weight - kg"
                      class="form-control"
                      v-model="allStorePObj.package_weight"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Minimum Item</label>
                    <input
                      type="number"
                      placeholder="Minimum Item"
                      class="form-control"
                      v-model="allStorePObj.min_limit"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Maximum item</label>
                    <input
                      type="number"
                      placeholder="Maximum item"
                      class="form-control"
                      v-model="allStorePObj.max_limit"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Minimum Stock Limit</label>
                    <input
                      type="number"
                      placeholder="Minimum Stock Limit"
                      class="form-control"
                      v-model="allStorePObj.minimun_stock_limit"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label">Video URL</label>
                    <input
                      type="text"
                      placeholder="Video URL"
                      class="form-control"
                      v-model="allStorePObj.video_url"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4>
                    SKU Information
                    <hr />
                  </h4>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Quantity</label>
                    <input
                      type="number"
                      placeholder="Quantity"
                      class="form-control"
                      v-model="allStorePObj.quantity"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Listing Price (RS)</label>
                    <input
                      type="number"
                      placeholder="Price"
                      class="form-control"
                      v-model="allStorePObj.price"
                      min="0"
                      @input="validateListingPrice('admin')"
                    />
                    <div v-show="!listSellPriceValid" style="font-size: 10px; color: red;">
                      Listing price should be equal or higher than to selling
                      price
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Selling Price (RS)</label>
                    <input
                      type="number"
                      placeholder="Special Price"
                      class="form-control"
                      v-model="allStorePObj.special_price"
                      min="0"
                      @input="validateSellingPrice('admin')"
                    />
                    <div v-show="!listSellPriceValid" style="font-size: 10px; color: red;">
                      Selling price should be equal or lower than to listing
                      price
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div
                    class="col-sm-3 imgUp"
                    style="padding-bottom:10px;"
                    v-for="(item, index) in allStorePObj.images"
                    :key="index"
                  >
                    <div class="imagePreview">
                      <img
                        v-if="item === ''"
                        src="http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg"
                        height="100%"
                      />
                      <img else :src="item" height="100%" />
                    </div>
                    <label class="btn btn-primary" style="display: block; margin-top: -5px;">
                      Upload
                      <input type="button" @click="showProductImageModal(index)" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden; display: none;">
                      <!-- <input
                        type="file"
                        @change="onFileChange($event, index)"
                        class="uploadFile img"
                        value="Upload Photo"
                        style="width: 0px;height: 0px;overflow: hidden; "
                      /> -->
                    </label>
                    <i @click="deleteImg(index)" class="fa fa-times del"></i>
                  </div>
                  <i @click="addimage()" class="fa fa-plus imgAdd"></i>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn merchprof-sbtn"
                @click="haddleClickSave()"
                :disabled="!formIsValidNew"
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal main end -->

    <!-- Modal store manager -->
    <div
      class="modal fade"
      id="storeManagerModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="storeManagerModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg"
        role="document"
        style="padding-top:10%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="float: right;"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="row align-contents">
              <h2 class="modal-title" style="margin-left: 20px;">Edit Product Details</h2>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <form action="#" method="post" enctype="multipart/form-data">
                <div class="col-sm-12">
                  <div class="form-group required" v-if="storeMPObj.product_name">
                    <label>
                      <B>{{ storeMPObj.product_name }}</B>
                    </label>
                  </div>
                </div>

                <!-- <div class="col-sm-6">
                  <div class="form-group required">
                    <label class="control-label">Quantity</label>
                    <input type="number" placeholder="Quantity" class="form-control" v-model="storeMPObj.quantity" min="0"/>
                  </div>
                </div>
                
                <div class="col-sm-6"></div>-->
                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Quantity</label>
                    <input
                      type="number"
                      placeholder="Quantity"
                      class="form-control"
                      v-model="storeMPObj.quantity"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Listing Price (RS)</label>
                    <input
                      type="number"
                      placeholder="Price"
                      class="form-control"
                      v-model="storeMPObj.price"
                      min="0"
                      disabled
                      @input="validateListingPrice('manager')"
                    />
                    <div v-show="!listSellPriceValidMng" style="font-size: 10px; color: red;">
                      Listing price should be equal or higher than to selling
                      price
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Selling Price (RS)</label>
                    <input
                      type="number"
                      placeholder="Special Price"
                      class="form-control"
                      v-model="storeMPObj.special_price"
                      min="0"
                      disabled
                      @input="validateSellingPrice('manager')"
                    />
                    <div v-show="!listSellPriceValidMng" style="font-size: 10px; color: red;">
                      Selling price should be equal or lower than to listing
                      price
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Minimum Item</label>
                    <input
                      type="number"
                      placeholder="Minimum Item"
                      class="form-control"
                      v-model="storeMPObj.min_limit"
                      min="0"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Maximum item</label>
                    <input
                      type="number"
                      placeholder="Maximum item"
                      class="form-control"
                      v-model="storeMPObj.max_limit"
                      min="0"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group required">
                    <label class="control-label">Minimum Stock Limit</label>
                    <input
                      type="number"
                      placeholder="Minimum Stock Limit"
                      class="form-control"
                      v-model="storeMPObj.minimun_stock_limit"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <h4>
                    Delivery Information
                    <hr />
                  </h4>

                  <!-- <div class="row">
                    <div class="col-sm-2" style="display: flex; align-items: center;">     
                      <ul class="product-options clearfix">
                        <li class="radio active">
                          <label class="radiocontainer">
                          <input type="radio" value="1"  @click="changePaymentType($event.target.value)">
                          <div class="check-background align-div " v-bind:class="classObject">
                              <img src="@/assets/image/icon/delivery_truck.png">
                            </div>
                          </label>
                        </li>
                      </ul>
                    
                      <ul class="product-options clearfix" style="margin-left:20px;">
                        <li class="radio active">
                          <label class="radiocontainer">
                          <input type="radio" value="2" @click="changePaymentType($event.target.value)">
                          <div class="check-background align-div"
                            v-bind:class="classObject1"
                          >
                            <img src="@/assets/image/icon/store_pickup.png">
                          </div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>-->
                </div>
                <div class="col-sm-12">
                  <label>Delivery Partner</label>
                  <select
                    disabled
                    class="form-control select-arrow"
                    v-model="storeMPObj.deliver"
                    @change="
                      haddleWrantyChange($event.target.value, 'deliveryInfo')
                    "
                  >
                    <option
                      v-for="(item, index) in deliverpartners"
                      :key="index"
                      :value="item._id"
                    >{{ item.first_name + " " + item.last_name }}</option>
                  </select>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn merchprof-sbtn"
                @click="haddleClickQTYSave()"
                :disabled="!formIsValid2"
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal store manager end -->

    <!-- product image submit modal start-->
    <div
      class="modal fade"
      id="productImageModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productImageModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-md"
        role="document"
        style="padding-top:10%"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="float: right;"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="row align-contents">
              <h2 class="modal-title" style="margin-left: 20px;">Product Image</h2>
            </div>
          </div>
          <div class="modal-body">

            <!-- <div class="row text-center text-lg-left" style="height: 400px; overflow-y: scroll;">
                <div class="col-lg-3 col-md-3 col-xs-12 imagePreview" style="margin-bottom: 40px;" v-for="item in galleryImages" :key="item._id">
                    <a class="d-block mb-4 h-100">
                        <img @click="haddleAddToImage(item.image)" class="img-fluid img-thumbnail" :src='item.image'>
                    </a>
                </div>
            </div> -->

           <div class="row text-center text-lg-left" style="height: 400px; overflow-y: scroll;">
            <div class="col-sm-3 imgUp" style="padding-bottom:10px;" v-for="item in galleryImages" :key="item._id">
              <div class="imagePreviewM0dal galaryImg">
                <img :src="item.image" height="100%" @click="haddleAddToImage(item.image)" />
              </div>
            </div>
           </div>


            <div class="row" style="margin-top: 20px;">
              <form action="#" method="post" enctype="multipart/form-data">
                <div class="col-sm-12">
                  <input type="text" placeholder="image url" class="form-control" v-model="imageUrl" />
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn merchprof-sbtn"
                @click="onFileChange()"
                :disabled="!isValid"
              >Save</button>
            </div>
          </div>
        </div>
      </div>
  </div>
  <!-- product image submit modal end-->  

  </div>
  </div>
</template>
<script src="/dist/vue-social-sharing.min.js"></script>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import mixinProfile from "@/mixins/merchant/APIProfile";
import mixinGalary from "@/mixins/merchant/APIGalary";
import mixinCategory from "@/mixins/merchant/APICategory";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

export default {
  comments:{
    popper: Popper,
  },
  mixins: [mixinProduct, mixinProfile, mixinGalary,mixinCategory],
  data: function() {
    return {
      isDisabled: true,
      statusObj: {},
      breadcrumb: [
        {
          text: "Product Overview",
          href: "/product-store",
          active: true
        }
      ],
      pageSize: 25,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      blockLoading: true,
      userRole: "",

      isVisibleAllEdit: false,
      deliverpartners: [],
      allStorePObj: {
        sku: "",
        product_name: "",
        description: "",
        price: 0,
        special_price: 0,
        video_url: "",
        package_weight: "",
        images: [],
        min_limit: "",
        max_limit: "",
        minimun_stock_limit: "",
        quantity: ""
      },
      storeMPObj: {
        product_name: "",
        product_id: "",
        price: "",
        special_price: "",
        sku: "",
        quantity: "",
        min_limit: "",
        max_limit: "",
        minimun_stock_limit: "",
        deliver: ""
      },
      fileUploadFormData: new FormData(),
      paymentTypeValue: 1,
      deliveryPaymentType: true,
      listSellPriceValid: false,
      listSellPriceValidMng: false,
      kycVerify: true,
      isDeliveryApprove : true,
      mainCategory: [],

      imageUrl: "",
      imageIndex: 0,
      searchMainCategory: "0",
      galleryImages: [],
      ProductStatusList: [
        {
          _id: "Pending Approval",
          status:"Pending Approval"
        },
        {
          _id: "Active",
          status:"Active"
        },
        {
          _id: "Deactivated",
          status:"Deactivated"
        },
        {
          _id: "Rejected",
          status:"Rejected"
        },
      ],
      searchProductStatus: "0"
    };
  },
  computed: {
    isValid() {
      return (
        this.imageUrl
      )
    },
    formIsValid() {
      return (
        this.allStorePObj.sku &&
        this.allStorePObj.product_name &&
        this.allStorePObj.description &&
        this.allStorePObj.special_price &&
        this.allStorePObj.min_limit &&
        this.allStorePObj.max_limit &&
        this.allStorePObj.minimun_stock_limit &&
        this.allStorePObj.quantity
        // this.allStorePObj.price
      );
    },
    formIsValid2() {
      return (
        this.storeMPObj.special_price &&
        this.storeMPObj.quantity &&
        this.storeMPObj.min_limit &&
        this.storeMPObj.max_limit &&
        this.storeMPObj.minimun_stock_limit &&
        this.storeMPObj.deliver &&
        this.listSellPriceValidMng == true
      );
    },
    formIsValidNew() {
      return (
        // this.allStorePObj.sku &&
        this.allStorePObj.product_name &&
        this.allStorePObj.description &&
        this.allStorePObj.special_price &&
        this.allStorePObj.min_limit &&
        this.allStorePObj.max_limit &&
        this.allStorePObj.minimun_stock_limit &&
        this.allStorePObj.quantity &&
        this.allStorePObj.price &&
        this.listSellPriceValid == true
      );
    },
    classObject: function() {
      if (this.paymentTypeValue == 1) {
        return "cash-icons-active";
      } else {
        return "cash-icons-deactive";
      }
    },
    classObject1: function() {
      if (this.paymentTypeValue == 2) {
        return "cash-icons-active";
      } else {
        return "cash-icons-deactive";
      }
    }
  },
  watch: {
    //  Do not activate this commented watchers because search functioned to work for search button event as required
    "$store.state.merchant_selectedstore": function(val) {
      this.setDefaultStoreToAll();
      if (val === "-1") {
        this.isVisibleAllEdit = true;
      } else {
        this.isVisibleAllEdit = false;
      }
      this.storeListData = [];
      this.handleGetProductList(this.searchKey);
    },
    "$store.state.fetchStoreMerchant_pId": function(val) {
      this.haddleEditOnlyQty(val);
    },
    searchKey: function(val) {
      if (this.searchKey === "") {
        this.isDisabled = true;
        this.handleGetProductList(this.searchKey);
      } else {
        this.isDisabled = false;
      }
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      let srchKey = "";
      this.searchCustomers(srchKey);
    }
  },
  created() {
    this.setDefaultStoreToAll();
    this.haddleStore();
    this.getCategories();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
    this.handleBreadcrumb();
    this.handleGetStores();
    this.handleGetProductList(this.searchKey);
    this.fetchDeliveryPartners();
  },
  methods: {
    setDefaultStoreToAll: function() {
      // localStorage.setItem("merchant_selectedstore", -1);
      if (localStorage.merchant_selectedstore !=="-1") {
        this.$store.state.merchant_selectedstore = localStorage.merchant_selectedstore;
        this.$store.dispatch("fetchMerchant_selectedstore");
        this.isVisibleAllEdit = false;
      }
    },
    haddleStore: function() {
      this.setDefaultStoreToAll();
      if (this.$store.state.merchant_selectedstore === "-1") {
        this.isVisibleAllEdit = true;
      } else {
        this.isVisibleAllEdit = false;
      }
    },
    haddleAD: async function(id, status) {
      if (status === 1) {
        this.statusObj._id = id;
        this.statusObj.active_status = 2;
      } else {
        this.statusObj._id = id;
        this.statusObj.active_status = 1;
      }
      try {
        await this.putProductStatus(this.statusObj);
        this.handleGetProductList(this.searchKey);
        this.$swal
          .fire({
            position: "center",
            type: "success",
            title: "Successfully changed product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      } catch (error) {
        this.$swal
          .fire({
            position: "center",
            type: "error",
            title: "Cant change product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      }
    },
    // active / deactive admin 
    haddleADAdmin: async function(id, status, sku) {
      if (status === 1) {
        this.statusObj._id = id;
        this.statusObj.active_status = 2;
        this.statusObj.sku = sku;
      } else {
        this.statusObj._id = id;
        this.statusObj.active_status = 1;
        this.statusObj.sku = sku;
      }
      try {
        await this.putProductStatusByAdmin(this.statusObj);
        this.handleGetProductList(this.searchKey);
        this.$swal
          .fire({
            position: "center",
            type: "success",
            title: "Successfully changed product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      } catch (error) {
        this.$swal
          .fire({
            position: "center",
            type: "error",
            title: "Cant change product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      }
    },
    // active / deactive admin end
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleCreate: function() {
      this.$router.push({ name: "Product Configure", params: { id: 0 } });
    },
    handleCreateBulk: function() {
      this.$router.push({ name: "Product Configure Bulk" });
    },
    handleUpdateStockBulk: function() {
      this.$router.push({ name: "Stock Configure Bulk" });
    },
    handleUpdateStockBulkSup: function() {
      this.$router.push({ name: "Stock Configure Bulk Sub" });
    },
    handleUpdateFreeshippingBulk: function() {
      this.$router.push({ name: "Stock Configure Freeshipping Bulk" });
    },
    // shareDiwShow() {
    //   var element = document.getElementsByClassName("shareDiv");
    //   element.classList.toggle("shareToggel");
    // },
    handleProduct: function() {
      this.$router.push({ name: "Merchant Product", params: { id: 0 } });
    },
    helpProponover: function() {
      var element = document.getElementById("helpDiv");
      element.classList.toggle("helpToggel");
    },
    handleGetStores: async function(page) {
      try {
        this.searchCustomers("");
      } catch (error) {
        throw error;
      }
    },
    handleGetProductList: async function(searchString) {
      try {
        this.setDefaultStoreToAll();
        this.getCategories();
        await this.getPersanalInfo();
        this.tableItems = [];
        this.blockLoading = true;
        if (this.userRole === 1 || this.userRole === 3 || this.userRole === 4 || this.userRole === 5 || this.userRole === 6 || this.userRole === 7) {
          if (this.$store.state.merchant_selectedstore === "-1") {
            this.storeListData = [];
            this.searchMainCategory = "0";
            let response = await this.getStoreProductData("", this.searchKey);
            for (let index = 0; index < this.mainCategory.length; index++) {
              const element = this.mainCategory[index];
              for (let index = 0; index < response.length; index++) {
                const element1 = response[index];
                if (element1.categories[0] === element._id) {
                  element1.category_name = element.category_name
                }
                element1.product_status = 0;
                switch (element1.admin_status +","+ element1.active_status) {
                  case "2,1":
                    element1.product_status = "Pending Approval";
                    break;
                  case "1,1":
                    element1.product_status = "Active";
                    break;
                  case "1,2":
                    element1.product_status = "Deactivated";
                    break;
                  case "2,2":
                    element1.product_status = "Rejected";
                    break;
                  default:
                    element1.product_status = "0"
                    break;
                }
                
              }
            }
            this.blockLoading = false;
            this.storeListData = response;
          } else {
            if (this.$store.state.merchant_selectedstore !== null) {
              this.storeListData = [];
              this.searchMainCategory = "0";
              let response = await this.getStoreProductData(
                this.$store.state.merchant_selectedstore,
                this.searchKey
              );
              for (let index = 0; index < this.mainCategory.length; index++) {
                const element = this.mainCategory[index];
                for (let index = 0; index < response.length; index++) {
                  const element1 = response[index];
                  if (element1.categories[0] === element._id) {
                    element1.category_name = element.category_name
                  }
                  element1.product_status = 0;
                  switch (element1.admin_status +","+ element1.active_status) {
                    case "2,1":
                      element1.product_status = "Pending Approval";
                      break;
                    case "1,1":
                      element1.product_status = "Active";
                      break;
                    case "1,2":
                      element1.product_status = "Deactivated";
                      break;
                    case "2,2":
                      element1.product_status = "Rejected";
                      break;
                    default:
                      element1.product_status = "0"
                      break;
                  }
                  
                }
              }
              this.blockLoading = false;
              this.storeListData = response.reverse();
            } else {
              this.storeListData = [];
            }
          }
        } else if (this.userRole === 2) {
          this.storeListData = [];
          this.searchMainCategory = "0";
          let response = await this.getStoreProductDataRole2(searchString);
          for (let index = 0; index < this.mainCategory.length; index++) {
            const element = this.mainCategory[index];
            for (let index = 0; index < response.length; index++) {
              const element1 = response[index];
              if (element1.categories[0] === element._id) {
                element1.category_name = element.category_name
              }
              
            }
          }
          this.blockLoading = false;
          this.storeListData = response.reverse();
        }
        this.blockLoading = false;
        this.searchCustomers("");
      } catch (error) {
        throw error;
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.searchProductStatus = "0";
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                obj.product_name &&
                obj.product_name.toLowerCase().includes(keyword.toLowerCase()) ||
                obj.categories[0] &&
                obj.categories[0].toLowerCase().includes(keyword.toLowerCase()) ||
                obj.product_status &&
                obj.product_status.toLowerCase().includes(keyword.toLowerCase())
              );
            }
          })
          .slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    filterMainCategory: function() {
      // console.log("this.searchMainCategory",this.searchMainCategory);
      if (this.searchMainCategory !== "0") {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                obj.categories[0] &&
                obj.categories[0].toLowerCase().includes(this.searchMainCategory.toLowerCase())
              );
            }
          })
          .slice(0, this.pageSize);
        this.rowcount = this.tableItems.length;
      } else {
        this.searchProductStatus = "0";
        this.searchKey = "";
        this.searchCustomers("")
      }
    },
    filterProductStatus: function() {
      if (this.searchProductStatus !== "0") {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                obj.product_status &&
                obj.product_status.toLowerCase().includes(this.searchProductStatus.toLowerCase())
              );
            }
          })
          .slice(0, this.pageSize);
        this.rowcount = this.tableItems.length;
      } else {
        this.searchMainCategory = "0";
        this.searchKey = "";
        this.searchCustomers("")
      }
    },
    getCategories: async function() {
      try {
        let status = await this.getMainCategories();
        this.mainCategory = status;
      } catch (error) {
        console.log(error);
      }
    },
    pushEdit(pid) {
      this.$router.push({ name: "Product Configure", params: { id: pid } });
    },
    handleView: function(pid) {
      this.$router.push({
        name: "Product Configure View",
        params: { id: pid }
      });
    },
    haddleEditAll: async function(pid) {
      $("#addressModalCenter").modal("show");

      this.allStorePObj.sku = "";
      this.allStorePObj.product_name = "";
      this.allStorePObj.description = "";
      this.allStorePObj.price = "";
      this.allStorePObj.special_price = "";
      (this.allStorePObj.video_url = ""),
        (this.allStorePObj.package_weight = ""),
        (this.allStorePObj.images = []),
        (this.allStorePObj.min_limit = ""),
        (this.allStorePObj.max_limit = ""),
        (this.allStorePObj.minimun_stock_limit = ""),
        (this.allStorePObj.quantity = "");
      try {
        let response = await this.getProductByID(pid);
        console.log("response")
        console.log(response)
        if (response) {
          this.allStorePObj.sku = response.simple_products[0].sku;
          this.allStorePObj.product_name = response.product_name;
          this.allStorePObj.description = response.description;
          this.allStorePObj.price = response.simple_products[0].price;
          this.allStorePObj.special_price =
            response.simple_products[0].special_price;
          (this.allStorePObj.video_url = response.video_url),
            (this.allStorePObj.package_weight = response.package_weight),
            (this.allStorePObj.images = response.simple_products[0].images.map(
              obj => {
                return obj.image;
              }
            ));
          this.allStorePObj.min_limit = response.min_limit;
          this.allStorePObj.max_limit = response.max_limit;
          this.allStorePObj.minimun_stock_limit = response.minimun_stock_limit;
          this.allStorePObj.quantity = response.simple_products[0].quantity;

          if (
            response.simple_products[0].price >=
            response.simple_products[0].special_price
          ) {
            this.listSellPriceValid = true;
          } else {
            this.listSellPriceValid = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    haddleClickSave: async function() {
      try {
        let response = await this.putAllStoreProductSave(this.allStorePObj);
        $("#addressModalCenter").modal("hide");
        if (response) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Successfully Updated!",
            showConfirmButton: false,
            timer: 1500
          });
          this.handleGetProductList(this.searchKey);
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    haddleEditOnlyQty: async function(pid) {
      $("#storeManagerModalCenter").modal("show");

      try {
        let response = await this.getProductByID(pid);
        if (response) {
          this.storeMPObj.sku = response.simple_products[0].sku;
          this.storeMPObj.product_name = response.product_name;
          this.storeMPObj.product_id = response._id;
          this.storeMPObj.quantity = response.simple_products[0].quantity;

          this.storeMPObj.price = response.simple_products[0].price;
          this.storeMPObj.special_price =
            response.simple_products[0].special_price;
          this.storeMPObj.min_limit = response.min_limit;
          this.storeMPObj.max_limit = response.max_limit;
          this.storeMPObj.minimun_stock_limit = response.minimun_stock_limit;
          this.storeMPObj.deliver =
            response.deliver._id != undefined ? response.deliver._id : "";

          if (
            response.simple_products[0].price >=
            response.simple_products[0].special_price
          ) {
            this.listSellPriceValidMng = true;
          } else {
            this.listSellPriceValidMng = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    haddleClickQTYSave: async function() {
      try {
        delete this.storeMPObj.product_name;
        let response = await this.putStoreProductQTYSave(this.storeMPObj);
        $("#storeManagerModalCenter").modal("hide");
        if (response) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Successfully Updated!",
            showConfirmButton: false,
            timer: 1500
          });
          let val = ""
          this.handleGetProductList(val);
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    deleteImg(index) {
      this.allStorePObj.images.splice(index, 1);
      if (this.allStorePObj.images.length === 0) {
        this.allStorePObj.images.push("");
      }
    },
    addimage() {
      if (this.allStorePObj.images.length === 3) {
        this.$swal.fire({
          backdrop: false,
          position: "center",
          type: "error",
          title: "Only Three Images Allowed Per SKU",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      this.allStorePObj.images.push("");
    },
    showProductImageModal: function(index){
      this.imageIndex = index;
      this.haddleImgGalaryGet();
      this.imageUrl = "";
      $("#productImageModalCenter").modal("show");
    },
    haddleImgGalaryGet: async function() {
      try {
          let response = await this.getGalleryList();
          this.galleryImages = response;
      } catch (error) {
          this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
          });
      }
    },
    onFileChange() {
      // var files = e.target.files || e.dataTransfer.files;
      // if (!files.length) return;
      // this.createImage(files[0], index);
      this.createImage(this.imageUrl, this.imageIndex);
      $("#productImageModalCenter").modal("hide");
      this.imageUrl = "";
    },
    createImage: async function(file, index) {
      try {
        // await this.fileUploadFormData.append("image", file);

        // let imgpost_obj = this.fileUploadFormData;

        // let status = await this.postSkuImage(imgpost_obj);
        // this.keydata.imgarray[index] = status.image;
        this.allStorePObj.images.splice(index, 1, file);
        this.fileUploadFormData = new FormData();
      } catch (error) {
        console.log(error);
      }
    },
    changePaymentType: function(val) {
      this.paymentTypeValue = val;
      if (val === "1") {
        this.deliveryPaymentType = true;
      } else if (val === "2") {
        this.deliveryPaymentType = false;
      }
      this.fetchDeliveryPartners();
    },
    fetchDeliveryPartners: async function() {
      try {
        let quaryVal = this.deliveryPaymentType;
        this.deliverpartners = [];
        let response = await this.getDeliveryPartners(quaryVal);
        if (response.length !== 0) {
          this.deliverpartners = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateListingPrice: function(type = "") {
      if (type == "admin") {
        this.allStorePObj.price = parseInt(this.allStorePObj.price);
        this.allStorePObj.special_price = parseInt(
          this.allStorePObj.special_price
        );

        this.allStorePObj.special_price = this.allStorePObj.price;

        this.listSellPriceValid = false;

        if (this.allStorePObj.price >= this.allStorePObj.special_price) {
          this.listSellPriceValid = true;
        } else {
          this.listSellPriceValid = false;
        }
      }

      if ((type = "manager")) {
        this.storeMPObj.price = parseInt(this.storeMPObj.price);
        this.storeMPObj.special_price = parseInt(this.storeMPObj.special_price);

        this.storeMPObj.special_price = this.storeMPObj.price;

        this.listSellPriceValidMng = false;

        if (this.storeMPObj.price >= this.storeMPObj.special_price) {
          this.listSellPriceValidMng = true;
        } else {
          this.listSellPriceValidMng = false;
        }
      }
    },
    validateSellingPrice: function(type = "") {
      if (type == "admin") {
        this.allStorePObj.price = parseInt(this.allStorePObj.price);
        this.allStorePObj.special_price = parseInt(
          this.allStorePObj.special_price
        );

        this.listSellPriceValid = false;

        if (this.allStorePObj.price >= this.allStorePObj.special_price) {
          this.listSellPriceValid = true;
        } else {
          this.listSellPriceValid = false;
        }
      }

      if ((type = "manager")) {
        this.storeMPObj.price = parseInt(this.storeMPObj.price);
        this.storeMPObj.special_price = parseInt(this.storeMPObj.special_price);

        this.listSellPriceValidMng = false;

        if (this.storeMPObj.price >= this.storeMPObj.special_price) {
          this.listSellPriceValidMng = true;
        } else {
          this.listSellPriceValidMng = false;
        }
      }
    },
    searchProduct: function() {
      if (this.searchKey) {
        this.isDisabled = false;
        this.handleGetProductList(this.searchKey);
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();

        if (response.verify_status === 2) {
          this.kycVerify = true;
        } else if (response.verify_status !== 2) {
          this.kycVerify = false;
        }

        if (response.deliver) {
          if (response.own_delivrey === true && response.own_delivrey_approve === false) {
            this.isDeliveryApprove = false;
          } else {
            this.isDeliveryApprove = true;
          }
        }



      } catch (error) {
        console.log(error);
      }
    },
    haddleAddToImage: function(image) {
      this.imageUrl = image;
    },
    copyUrl: function (id) {
      let currUrl = window.location.href.split("product-store");
      let newUrl = currUrl[0] + "product?pid=" + id;
      this.$copyText(newUrl);
      this.$swal.fire({
        position: "top-end",
        type: "success",
        title: "Copied!",
        showConfirmButton: false,
        timer: 800,
      });
    },
    openProductView: function (id) {
      let currUrl = window.location.href.split("product-store");
      let newUrl = currUrl[0] + "product?pid=" + id;
      window.open(newUrl, '_blank');
    }
  }
};
</script>
<style scoped>
.imagePreview {
  width: 100%;
  height: 180px;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4bd7ef;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  margin-top: 0px;
  cursor: pointer;
  font-size: 15px;
}
.del {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

#helpDiv {
  display: none;
  position: absolute;
  right: 0px;
  top: 56px;
  border: 1px solid transparent;
  box-shadow: 1px 2px 14px #ccc;
  z-index: 1;
  background-color: white;
}

.helpToggel {
  display: block !important;
}
/* Decorations */
.radiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

.radiocontainer .checkmark:after {
  top: 10px;
  left: 8px;
  /* width: 20px;
height: 20px; */
  border-radius: 5%;
  background: transparent;
}
.check-background:after {
  content: "";
  position: absolute;
  display: none;
}

.shareDiv {
  /* position: absolute;
  right: 0px;
  top: auto; */
  border: 1px solid transparent;
  box-shadow: 1px 2px 14px #ccc;
  z-index: 1;
}

.radiocontainer input:checked ~ .check-background:after {
  display: block;
}

.radiocontainer .check-background:after {
  top: 0px;
  left: 0px;
  /* width: 100%;
height: 100%; */
  border-radius: 5%;
  /* background-color: rgb(0, 91, 170); color: rgb(255, 255, 255); */
  color: white !important;
  border: 1px solid #666666;
}
.align-div {
  width: 70px;
  height: 70px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.arrow:before {
  position: absolute;
  top: -6px;
  right: 94px;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  background-color: #fff;
  box-shadow: 2px 1px 14px #ccc !important;
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  z-index: -1;
}

</style>
<style>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.product-action-style {
  min-width: 280px;
  width: auto;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchprdlist .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
}
.merchprdlist .merchprof-sbtn:hover {
  color: #000000;
}

.galaryImg {
  width: 100%;
  height: 140px;
  cursor: pointer;
}

.galaryImg:hover {
  width: 100%;
  cursor: pointer;
  height: 140px;
  border: 5px solid #666666;
}

.imagePreviewM0dal {
  width: 100%;
  height: 140px;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
</style>
